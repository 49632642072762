const EMAIL_REGEXP = /^[^@\s]+@[^@\s]+\.[^@\s]+$/iu;

const checkFormFieldsForActivateSubmitBtn = (personalBlock) => {
  const emailEdit = document.querySelector('.account__form--active').querySelector('.connection__field--edit-email');
  const passwordEdit = personalBlock.querySelector('.connection__field--edit-password');
  const passwordRepeatEdit = personalBlock.querySelector('.connection__field--edit-password-repeat');

  const nameEdit = personalBlock.querySelector('.connection__field--edit-name');
  const dateBirthEdit = personalBlock.querySelector('.connection__field--edit-birth-date');
  const phoneEdit = personalBlock.querySelector('.connection__field--edit-phone');

  const buttonSubmit = personalBlock.querySelector('.connection__button--submit');

  if (EMAIL_REGEXP.test(emailEdit.value) && passwordEdit.value !== "" && (passwordEdit.value === passwordRepeatEdit.value
    && nameEdit.value !== "" && dateBirthEdit.value.length >= 10 && phoneEdit.value.length >= 18)) {
    buttonSubmit.classList.add('connection__button--active');
  } else {
    buttonSubmit.classList.remove('connection__button--active');
  }
};

const initPersonal = () => {
  const personalBlock = document.querySelector('.account__form--personal');
  if (!personalBlock) return false;

  const emailEdit = document.querySelector('.account__form--active').querySelector('.connection__field--edit-email');
  const passwordEdit = personalBlock.querySelector('.connection__field--edit-password');
  const passwordRepeatEdit = personalBlock.querySelector('.connection__field--edit-password-repeat');

  const nameEdit = personalBlock.querySelector('.connection__field--edit-name');
  const dateBirthEdit = personalBlock.querySelector('.connection__field--edit-birth-date');
  const phoneEdit = personalBlock.querySelector('.connection__field--edit-phone');

  const checkboxNotificationEditLabel = personalBlock.querySelector('.checkbox--notification');
  const buttonSubmit = personalBlock.querySelector('.connection__button--submit');

  if (emailEdit && passwordEdit && passwordRepeatEdit && nameEdit) {
    emailEdit.addEventListener('input', function () {
      checkFormFieldsForActivateSubmitBtn(personalBlock);
      if (!EMAIL_REGEXP.test(emailEdit.value)) {
        document.querySelector('.error-message--edit-email').classList.remove('visually-hidden');
        emailEdit.classList.add('connection__field--error');
      } else {
        document.querySelector('.error-message--edit-email').classList.add('visually-hidden');
        emailEdit.classList.remove('connection__field--error');
      }
    });
    passwordEdit.addEventListener('input', function () {
      checkFormFieldsForActivateSubmitBtn(personalBlock);
    });
    passwordRepeatEdit.addEventListener('input', function () {
      checkFormFieldsForActivateSubmitBtn(personalBlock);
      if (passwordRepeatEdit.value !== passwordEdit.value) {
        document.querySelector('.error-message--edit').classList.remove('visually-hidden');
        passwordRepeatEdit.classList.add('connection__field--error');
      } else {
        document.querySelector('.error-message--edit').classList.add('visually-hidden');
        passwordRepeatEdit.classList.remove('connection__field--error');
      }
      if (passwordRepeatEdit.value === "") {
        document.querySelector('.error-message--edit-none').classList.remove('visually-hidden');
        passwordRepeatEdit.classList.add('connection__field--error');
      } else {
        document.querySelector('.error-message--edit-none').classList.add('visually-hidden');
        passwordRepeatEdit.classList.remove('connection__field--error');
      }
    });
    nameEdit.addEventListener('input', function () {
      checkFormFieldsForActivateSubmitBtn(personalBlock);
    });
    dateBirthEdit.addEventListener('input', function () {
      checkFormFieldsForActivateSubmitBtn(personalBlock);
    });
    phoneEdit.addEventListener('input', function () {
      checkFormFieldsForActivateSubmitBtn(personalBlock);
    });
    checkboxNotificationEditLabel.addEventListener('click', function () {
      buttonSubmit.classList.add('connection__button--active');
    });
  }
};

export {initPersonal};
