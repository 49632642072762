const initMap = () => {
  const mapItems = document.querySelectorAll('.map__item');
  if (mapItems) {
    mapItems.forEach(function (item) {
      item.onmouseenter = function () {
        var itemID = this.id;
        document.getElementById(`${itemID}-img`).querySelector('.map__img').classList.add('visually-hidden');
        document.getElementById(`${itemID}-img`).querySelector('.map__img-active').classList.remove('visually-hidden');
        document.getElementById(`${itemID}`).classList.add('map__item--active');
        document.getElementById(`${itemID}-img`).querySelector('.map__number').classList.add('map__number--img-active');
      };
      item.onmouseleave = function () {
        var itemID = this.id;
        document.getElementById(`${itemID}-img`).querySelector('.map__img').classList.remove('visually-hidden');
        document.getElementById(`${itemID}-img`).querySelector('.map__img-active').classList.add('visually-hidden');
        document.getElementById(`${itemID}`).classList.remove('map__item--active');
        document.getElementById(`${itemID}-img`).querySelector('.map__number').classList.remove('map__number--img-active');
      };
    });
  }
}

export {initMap}


