const initFavorite = () => {

  const lotsItem = document.querySelectorAll('.lots__item');
  if (lotsItem) {
    lotsItem.forEach(function (lot) {
      const btn_to_favorite = lot.querySelector('.favorite');
      if (btn_to_favorite) {

        btn_to_favorite.addEventListener('click', function (e) {
          const btn = (e.target === btn_to_favorite) ? e.target : e.target.closest('.favorite');
          const url = btn.dataset.url;
          const action = btn.closest('.lots__item').classList.contains('lots__item--favorite') ? 'del' : 'add';

          async function fetchData(url, action) {
            try {
              let params = new FormData();
              params.set('action', action);

              const response = await fetch(url, {
                method: 'post',
                body: params
              });
              if (!response.ok) throw new Error('Проблема с fetch');
              const data = await response.text();
              console.log(data);
              if (data == 1) {
                if (action == 'add') {
                  lot.classList.add('lots__item--favorite');
                } else {
                  lot.classList.remove('lots__item--favorite');
                }
              }
            } catch (error) {
              console.error('Ошибка fetch:', error);
            }
          }

          fetchData(url, action);
        });
      }
    });

  }
  const cardSigns = document.querySelectorAll('.card__signs-wrapper');
  if (cardSigns) {
    cardSigns.forEach(card => {
      const button = card.querySelector('.favorite__button');
      button.addEventListener('click', function () {
        button.querySelector('.favorite').classList.toggle('favorite--active');
      });
    })
  }
};

export {initFavorite};
