const EMAIL_REGEXP = /^[^@\s]+@[^@\s]+\.[^@\s]+$/iu;

const checkFormFieldsForActivateSubmitBtn = (loginBlock) => {
  const emailEnter = loginBlock.querySelector('.connection__field--email');
  const passwordEnter = loginBlock.querySelector('.connection__field--password');
  const buttonSubmit = loginBlock.querySelector('.connection__button--submit');

  if (passwordEnter.value !== "" && emailEnter.value !== "" && EMAIL_REGEXP.test(emailEnter.value)) {
    buttonSubmit.classList.add('connection__button--active');
  } else {
    buttonSubmit.classList.remove('connection__button--active');
  }
};

const initLogin = () => {
  const loginBlock = document.querySelector('.log-in__form--main');
  if (!loginBlock) return false;

  const emailEnter = loginBlock.querySelector('.connection__field--email');
  const passwordEnter = loginBlock.querySelector('.connection__field--password');

  if (emailEnter && passwordEnter) {
    passwordEnter.addEventListener('input', function () {
      checkFormFieldsForActivateSubmitBtn(loginBlock);
    });
    emailEnter.addEventListener('input', function () {
        checkFormFieldsForActivateSubmitBtn(loginBlock);
        if (!EMAIL_REGEXP.test(emailEnter.value)) {
          document.querySelector('.error-message-enter').classList.remove('visually-hidden');
          document.querySelector('.connection__field--email').classList.add('connection__field--error');
        } else {
          document.querySelector('.error-message-enter').classList.add('visually-hidden');
          document.querySelector('.connection__field--email').classList.remove('connection__field--error');
        }
      }
    );
  }
};

const checkRecoveryFormFieldsForActivateSubmitBtn = (recoveryBlock) => {
  const emailRecovery = recoveryBlock.querySelector('.connection__field--recovery');
  const buttonSubmit = recoveryBlock.querySelector('.modal__button-send');

  if (emailRecovery.value !== "" && EMAIL_REGEXP.test(emailRecovery.value)) {
    buttonSubmit.classList.add('modal__button-send--active');
  } else {
    buttonSubmit.classList.remove('modal__button-send--active');
  }
};

const initRecoveryForm = (recoveryBlock) => {
  const emailRecovery = recoveryBlock.querySelector('.connection__field--recovery');
  const buttonRecovery = recoveryBlock.querySelector('[data-button="send"]');
  const modalText = recoveryBlock.querySelector('.modal__text');

  const recoverySuccess = document.querySelector('[data-modal="success-password-recovery"]');

  if (emailRecovery) {
    emailRecovery.addEventListener('input', function () {
      checkRecoveryFormFieldsForActivateSubmitBtn(recoveryBlock);
    });
  }

  if (buttonRecovery) {
    buttonRecovery.addEventListener('click', function (e) {
      e.preventDefault();
      const form = buttonRecovery.closest('form');
      const url = form.action;
      const method = form.method;
      const params = new FormData(form);
      params.set('ajax', 1);

      async function fetchData(url, options) {
        try {
          const response = await fetch(url, options);
          if (!response.ok) throw new Error('Проблема с fetch');
          let data = await response.text();
          let newDiv = document.createElement('div');
          newDiv.innerHTML = data;
          modalText.innerHTML = newDiv.querySelector('.modal__text').innerHTML;
          if (newDiv.querySelector('.success')) {
            recoveryBlock.classList.add('visually-hidden');
            recoveryBlock.querySelector('.message').remove();
            recoverySuccess.classList.remove('visually-hidden');
          }
          initRecoveryForm(recoveryBlock);
          checkRecoveryFormFieldsForActivateSubmitBtn(recoveryBlock);
        } catch (error) {
          console.error('Ошибка fetch:', error);
        }
      }

      fetchData(url, {
        method: method,
        body: params
      });
    });
  }
}

const initRecoveryPassword = () => {
  const recoveryBlock = document.getElementById('password-recovery');
  if (!recoveryBlock) return false;

  const buttonRecoveryPassword = document.querySelector('[data-button="recovery-password"]');
  const buttonClosePasswordRecovery = document.querySelector('[data-modal="password-recovery"]');

  const recoverySuccess = document.querySelector('[data-modal="success-password-recovery"]');
  const buttonCloseSuccessPasswordRecovery = recoverySuccess.querySelector('[data-button="close-modal"]');

  if (buttonRecoveryPassword) {
    buttonRecoveryPassword.addEventListener('click', function () {
      document.querySelector('[data-modal="password-recovery"]').classList.remove('visually-hidden');
    });
  }

  if (buttonClosePasswordRecovery) {
    buttonClosePasswordRecovery.querySelector('[data-button="close-modal"]').addEventListener('click', function () {
      document.querySelector('[data-modal="password-recovery"]').classList.add('visually-hidden');
    });
  }

  if (buttonCloseSuccessPasswordRecovery) {
    buttonCloseSuccessPasswordRecovery.addEventListener('click', function () {
      recoverySuccess.classList.add('visually-hidden');
    });
  }

  initRecoveryForm(recoveryBlock);
};

export {initLogin, initRecoveryPassword};
