const EMAIL_REGEXP = /^[^@\s]+@[^@\s]+\.[^@\s]+$/iu;

const checkFormFieldsForActivateSubmitBtn = (registrationBlock) => {
  const emailReg = registrationBlock.querySelector('.connection__field--reg-email');
  const passwordReg = registrationBlock.querySelector('.connection__field--reg-password');
  const passwordRepeat = registrationBlock.querySelector('.connection__field--reg-password-repeat');

  const name = registrationBlock.querySelector('.connection__field--name');
  const dateBirth = registrationBlock.querySelector('.connection__field--birth-date');
  const phone = registrationBlock.querySelector('.connection__field--phone');

  const checkboxAgreement = document.querySelector('.checkbox--agreement').querySelector('.checkbox__input');
  const checkboxData = document.querySelector('.checkbox--data').querySelector('.checkbox__input');
  const checkboxNotification = document.querySelector('.checkbox--notification').querySelector('.checkbox__input');
  const buttonSubmit = registrationBlock.querySelector('.connection__button--submit');

  if ((passwordRepeat.value === passwordReg.value) && EMAIL_REGEXP.test(emailReg.value) && checkboxNotification.checked
    && checkboxData.checked && checkboxAgreement.checked && passwordReg.value !== "" && emailReg.value !== ""
    && passwordRepeat.value !== "" && name.value !== "" && dateBirth.value.length >= 10 && phone.value.length >= 18) {
    buttonSubmit.classList.add('connection__button--active');
  } else {
    buttonSubmit.classList.remove('connection__button--active');
  }
};

const initRegistration = () => {
  const registrationBlock = document.querySelector('.log-in__form--registration');
  if (!registrationBlock) return false;

  const emailReg = registrationBlock.querySelector('.connection__field--reg-email');
  const passwordReg = registrationBlock.querySelector('.connection__field--reg-password');
  const passwordRepeat = registrationBlock.querySelector('.connection__field--reg-password-repeat');

  const name = registrationBlock.querySelector('.connection__field--name');
  const dateBirth = registrationBlock.querySelector('.connection__field--birth-date');
  const phone = registrationBlock.querySelector('.connection__field--phone');

  const checkboxAgreementLabel = document.querySelector('.checkbox--agreement');
  const checkboxDataLabel = document.querySelector('.checkbox--data');
  const checkboxNotificationLabel = document.querySelector('.checkbox--notification');

  const buttonRegistration = document.querySelector('[data-button="registration"]');
  const formSuccess = document.querySelector('[data-modal="success-registration"]');
  const formSuccessTitle = formSuccess.querySelector('.modal__title');
  const formSuccessText = formSuccess.querySelector('.modal__text');

  if (emailReg && passwordReg && passwordRepeat && name && dateBirth && phone && checkboxAgreementLabel && checkboxNotificationLabel && checkboxDataLabel) {
    passwordReg.addEventListener('input', function () {
      checkFormFieldsForActivateSubmitBtn(registrationBlock);
    });
    emailReg.addEventListener('input', function () {
        checkFormFieldsForActivateSubmitBtn(registrationBlock);
        if (!EMAIL_REGEXP.test(emailReg.value)) {
          document.querySelector('.error-message').classList.remove('visually-hidden');
          document.querySelector('.connection__field--reg-email').classList.add('connection__field--error');
        } else {
          document.querySelector('.error-message').classList.add('visually-hidden');
          document.querySelector('.connection__field--reg-email').classList.remove('connection__field--error');
        }
      }
    );
    passwordRepeat.addEventListener('input', function () {
        checkFormFieldsForActivateSubmitBtn(registrationBlock);
        if (passwordRepeat.value !== passwordReg.value) {
          document.querySelector('.error-message--reg-password').classList.remove('visually-hidden');
          passwordRepeat.classList.add('connection__field--error');
        } else {
          document.querySelector('.error-message--reg-password').classList.add('visually-hidden');
          passwordRepeat.classList.remove('connection__field--error');
        }
        if (passwordRepeat.value === "") {
          document.querySelector('.error-message--reg-password-none').classList.remove('visually-hidden');
          passwordRepeat.classList.add('connection__field--error');
        } else {
          document.querySelector('.error-message--reg-password-none').classList.add('visually-hidden');
          passwordRepeat.classList.remove('connection__field--error');
        }
      }
    );
    name.addEventListener('input', function () {
        checkFormFieldsForActivateSubmitBtn(registrationBlock);
      }
    );
    dateBirth.addEventListener('input', function () {
        checkFormFieldsForActivateSubmitBtn(registrationBlock);
      }
    );
    phone.addEventListener('input', function () {
        checkFormFieldsForActivateSubmitBtn(registrationBlock);
      }
    );
    checkboxAgreementLabel.addEventListener('click', function () {
      checkFormFieldsForActivateSubmitBtn(registrationBlock);
    });
    checkboxNotificationLabel.addEventListener('click', function () {
      checkFormFieldsForActivateSubmitBtn(registrationBlock);
    });
    checkboxDataLabel.addEventListener('click', function () {
      checkFormFieldsForActivateSubmitBtn(registrationBlock);
    });
  }

  if (buttonRegistration) {
    buttonRegistration.addEventListener('click', function () {
      const form = buttonRegistration.closest('form');
      const url = form.action;
      const method = form.method;
      const params = new FormData(form);

      async function fetchData(url, options) {
        try {
          const response = await fetch(url, options);
          if (!response.ok) throw new Error('Проблема с fetch');
          let data = await response.text();
          // console.log(data);
          let newDiv = document.createElement("div");
          newDiv.innerHTML = "data";
          if (newDiv.querySelector('form')) {
            registrationBlock.innerHTML = data;
            initRegistration();
          } else {
            data = JSON.parse(data);

            formSuccessTitle.innerHTML = data.title;
            formSuccessText.innerHTML = data.text;

            formSuccess.classList.remove('visually-hidden');
            form.reset();
            checkFormFieldsForActivateSubmitBtn(registrationBlock);
          }
        } catch (error) {
          console.error('Ошибка fetch:', error);
        }
      }

      fetchData(url, {
        method: method,
        body: params
      });
    });
  }
};

export {initRegistration};
