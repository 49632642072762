import { iosVhFix } from './utils/ios-vh-fix';

import IMask from 'imask';

import { initLotsSlider } from './modules/sliders/init-lots-slider';
import { initNewsSlider } from './modules/sliders/init-news-slider';
import { initMap } from './main/map.js';

import { initAccordions } from './modules/accordion/init-accordion';
import {iosChecker} from "./utils/ios-checker.js";
import { initDropdowns } from './auctions/filter';
import { initPersonal } from "./auth/personal.js";
import { initLogin, initRecoveryPassword } from "./auth/login.js";
import { initRegistration } from "./auth/registration.js";
import { initFavorite } from "./auctions/favorite.js";

// ---------------------------------

window.addEventListener('DOMContentLoaded', () => {
  iosVhFix();

  window.addEventListener('resize', () => {
    screenWidth = window.innerWidth;
    if (document.querySelector('.navigation__list--open')) {
      if (screenWidth >= 1440) {
        document.querySelector('.navigation__list--desktop').classList.remove('navigation__list--open');
        document.querySelector('.page').classList.remove('page--dark');
        document.querySelector('.navigation__button').classList.remove('navigation__button--active');
      }
    }
  });

  const lots = document.querySelectorAll('.lots__item');
  if (lots) {
    lots.forEach(function (item) {
      item.querySelector('a').onmouseenter = function () {
        item.classList.add('lots__item--active');
      };
      item.querySelector('a').onmouseleave = function () {
        item.classList.remove('lots__item--active');
      };
    });
  }

  const viewPasswordButtons = document.querySelectorAll('.connection__view-password');
  if (viewPasswordButtons) {
    viewPasswordButtons.forEach(viewPassword => {
      viewPassword.addEventListener('click', function () {
        const passwordField = viewPassword.parentElement;
        if (passwordField.querySelector('input').getAttribute('type') == 'password') {
          passwordField.querySelector('input').setAttribute('type', 'text');
        } else {
          passwordField.querySelector('input').setAttribute('type', 'password');
        }
      })
    })

  }

  initMap();

  const imgMapItems = document.querySelectorAll('.map__number--img');
  if (imgMapItems) {
    imgMapItems.forEach(function (item) {
      item.onmouseenter = function () {
        var itemMapID = this.id;
        document.getElementById(`${itemMapID}-map-img`).querySelector('.map__img').classList.add('visually-hidden');
        document.getElementById(`${itemMapID}-map-img`).querySelector('.map__img-active').classList.remove('visually-hidden');
        document.getElementById(`${itemMapID}`).classList.add('map__number--img-active');
        document.getElementById(`${itemMapID}-map`).classList.add('map__item--active');
      };
      item.onmouseleave = function () {
        var itemMapID = this.id;
        document.getElementById(`${itemMapID}-map-img`).querySelector('.map__img').classList.remove('visually-hidden');
        document.getElementById(`${itemMapID}-map-img`).querySelector('.map__img-active').classList.add('visually-hidden');
        document.getElementById(`${itemMapID}`).classList.remove('map__number--img-active');
        document.getElementById(`${itemMapID}-map`).classList.remove('map__item--active');
      };
    });
  }




  const buttonCloseRegistration = document.querySelector('[data-modal="success-registration"]');
  if (buttonCloseRegistration) {
    buttonCloseRegistration.querySelector('[data-button="close-modal"]').addEventListener('click', function () {
      document.querySelector('[data-modal="success-registration"]').classList.add('visually-hidden');
    });
  }

  const lotItems = document.querySelectorAll('.lots__item');
  if (lotItems) {
    lotItems.forEach(i => {
      const addFavLotButton = i.querySelector('.favorite');
      if (addFavLotButton) {
        addFavLotButton.addEventListener('click', function (e) {
          e.preventDefault();
        })
      }
    });
  }

  const lotTitles = document.querySelectorAll('.lots__lot-name');

  function checkHeight() {
    let maxHeight = 0;
    lotTitles.forEach(i => {
      if (i.offsetHeight > maxHeight) {
        maxHeight = i.offsetHeight;
      }
    })

    lotTitles.forEach(i => {
      i.style.height = maxHeight + "px";

    })
  }

  checkHeight();

  window.addEventListener('resize', () => {
    lotTitles.forEach(i => {
      i.style.height = "auto";
    })

    checkHeight();
  });

  const phoneMask = document.getElementById('phone');
  const repeatPhoneMask = document.getElementById('phone-repeat');
  const maskOptions = {
    mask: '+{7} (000) 000-00-00',
  };

  if (phoneMask) {
    const mask = IMask(phoneMask, maskOptions);
  }

  if (repeatPhoneMask) {
    const mask = IMask(repeatPhoneMask, maskOptions);
  }

  const dateMask = document.getElementById('birth-date');

  if (dateMask) {
    IMask(dateMask, {
      mask: Date,
      // lazy: false,
      min: new Date(1900, 0, 1),
      max: new Date(2024, 0, 1),

      blocks: {
        YYYY: {
          mask: IMask.MaskedRange,
          from: 1900,
          to: 2024,
        },
        MM: {
          mask: IMask.MaskedRange,
          from: 1,
          to: 12,
        },
        DD: {
          mask: IMask.MaskedRange,
          from: 1,
          to: 31,
        },
      },
    });
  };


  const loginForm = document.querySelector('.log-in__navigation-item--login');
  const registrationForm = document.querySelector('.log-in__navigation-item--registration');

  if (loginForm) {
    loginForm.addEventListener('click', function () {
      document.querySelector('.log-in__navigation-item--active').classList.remove('log-in__navigation-item--active');
      loginForm.classList.add('log-in__navigation-item--active');
      document.querySelector('.log-in__form--main').classList.add('log-in__form--active');
      document.querySelector('.log-in__form--main').classList.remove('visually-hidden');
      document.querySelector('.log-in__form--registration').classList.add('visually-hidden');
    });

    registrationForm.addEventListener('click', function () {
      document.querySelector('.log-in__navigation-item--active').classList.remove('log-in__navigation-item--active');
      registrationForm.classList.add('log-in__navigation-item--active');
      document.querySelector('.log-in__form--registration').classList.add('.log-in__form--active');
      document.querySelector('.log-in__form--registration').classList.remove('visually-hidden');
      document.querySelector('.log-in__form--main').classList.add('visually-hidden');
    });
  }


  const personAccount = document.querySelector('.account__navigation-item--personal');
  const favoriteAccount = document.querySelector('.account__navigation-item--favorite');
  const archiveAccount = document.querySelector('.account__navigation-item--archive');

  if (personAccount) {
    personAccount.addEventListener('click', function () {
      document.querySelector('.account__navigation-item--active').classList.remove('account__navigation-item--active');
      personAccount.classList.add('account__navigation-item--active');
      document.querySelector('.account__form--personal').classList.add('.account__form--active');
      document.querySelector('.account__form--personal').classList.remove('visually-hidden');
      document.querySelector('.account__form--favorite').classList.add('visually-hidden');
      document.querySelector('.account__form--archive').classList.add('visually-hidden');
    });

    favoriteAccount.addEventListener('click', function () {
      document.querySelector('.account__navigation-item--active').classList.remove('account__navigation-item--active');
      favoriteAccount.classList.add('account__navigation-item--active');
      document.querySelector('.account__form--favorite').classList.add('.account__form--active');
      document.querySelector('.account__form--favorite').classList.remove('visually-hidden');
      document.querySelector('.account__form--personal').classList.add('visually-hidden');
      document.querySelector('.account__form--archive').classList.add('visually-hidden');
    });

    archiveAccount.addEventListener('click', function () {
      document.querySelector('.account__navigation-item--active').classList.remove('account__navigation-item--active');
      archiveAccount.classList.add('account__navigation-item--active');
      document.querySelector('.account__form--archive').classList.add('.account__form--active');
      document.querySelector('.account__form--archive').classList.remove('visually-hidden');
      document.querySelector('.account__form--personal').classList.add('visually-hidden');
      document.querySelector('.account__form--favorite').classList.add('visually-hidden');
    });

  }

  setTimeout(initLotsSlider(), 1000);
  setTimeout(initNewsSlider(), 1000);
  setTimeout(initAccordions(), 1000);

  var swiper3 = new Swiper(".mySwiper3", {
    slidesPerView: 'auto',
    freeMode: true,
    watchSlidesProgress: true,
    // allowTouchMove: false,
  });
  // eslint-disable-next-line
  var swiper2 = new Swiper(".mySwiper2", {

    breakpoints: {
      1440: {
        slidesPerView: 1,
      },

      768: {
        slidesPerView: 3,
      },

      500: {
        slidesPerView: 2,
      },

      320: {
        slidesPerView: 1,
        spaceBetween: 40,
      },
    },
    spaceBetween: 10,
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
    pagination: {
      el: '.swiper-pagination',
      clickable: true,
    },
    thumbs: {
      swiper: swiper3,
    },
  });

  var screenWidth = window.innerWidth;

  const photoSwiper = document.querySelector('.mySwiper2');
  if (photoSwiper) {
    const photos = photoSwiper.querySelectorAll('.lots__photo-item');
    const closeZoomModal = document.querySelector('.zoom--closed');

    if (screenWidth < 500) {
      swiper3.params.slidesPerView = 3;
      swiper3.update();
      swiper2.params.slidesPerView = 1;
      swiper2.update();
    }
    if (screenWidth >= 500 && screenWidth < 768) {
      swiper3.params.slidesPerView = 3;
      swiper3.update();
      swiper2.params.slidesPerView = 2;
      swiper2.update();
    }
    if (screenWidth >= 768 && screenWidth < 1440) {
      swiper3.params.slidesPerView = 3;
      swiper3.update();
      swiper2.params.slidesPerView = 3;
      swiper2.update();
    }
    if (screenWidth >= 1440) {
      swiper3.params.slidesPerView = 'auto';
    swiper3.params.spaceBetween = 0;
    // swiper3.params.allowTouchMove = false;
    //       console.log(swiper3.params.allowTouchMove, 'false');
      swiper3.update();
      swiper2.params.slidesPerView = 1;
      swiper2.update();
    }

    photos.forEach(photo => {
      photo.addEventListener('click', function () {
        screenWidth = window.innerWidth;
        if (screenWidth < 1440) {
          swiper3.params.slidesPerView = 4;
          swiper3.params.spaceBetween = 10;
          // swiper3.params.allowTouchMove = true;
          // console.log(swiper3.params.allowTouchMove, 'true');
          swiper3.update();
          swiper2.params.slidesPerView = 1;
          swiper2.update();
        }
        if (screenWidth >= 1440) {
          swiper3.params.slidesPerView = 6;
          swiper3.params.spaceBetween = 10;
          // swiper3.params.allowTouchMove = false;
          // console.log(swiper3.params.allowTouchMove, 'false');
          swiper3.update();
          swiper2.params.slidesPerView = 1;
          swiper2.update();
        }
        document.querySelector('.page-card').classList.add('page-card--dark');
        document.querySelector('.card__slider').classList.add('card__slider--zoom');
      });
    })
    closeZoomModal.addEventListener('click', function () {
      document.querySelector('.page-card').classList.remove('page-card--dark');
      document.querySelector('.card__slider').classList.remove('card__slider--zoom');
      screenWidth = window.innerWidth;
      if (screenWidth < 500) {
        swiper3.params.slidesPerView = 3;
        swiper3.update();
        swiper2.params.slidesPerView = 1;
        swiper2.update();
      }
      if (screenWidth >= 500 && screenWidth < 768) {
        swiper3.params.slidesPerView = 3;
        swiper3.update();
        swiper2.params.slidesPerView = 2;
        swiper2.update();
      }
      if (screenWidth >= 768 && screenWidth < 1440) {
        swiper3.params.slidesPerView = 3;
        swiper3.update();
        swiper2.params.slidesPerView = 3;
        swiper2.update();
      }
      if (screenWidth >= 1440) {
        swiper3.params.slidesPerView = 'auto';
        swiper3.params.spaceBetween = 0;
        // swiper3.params.allowTouchMove = false;
        // console.log(swiper3.params.allowTouchMove, 'false');
        swiper3.update();
        swiper2.params.slidesPerView = 1;
        swiper2.update();
        // location.reload();
      }
    })

    window.addEventListener('resize', () => {
      screenWidth = window.innerWidth;
      if (document.querySelector('.card__slider--zoom')) {
        if (screenWidth < 1440) {
          swiper3.params.slidesPerView = 4;
          swiper3.params.spaceBetween = 10;
          // swiper3.params.allowTouchMove = true;
          // console.log(swiper3.params.allowTouchMove, 'true');
          swiper3.update();
          swiper2.params.slidesPerView = 1;
          swiper2.update();
        }
        if (screenWidth >= 1440) {
          swiper3.params.slidesPerView = 6;
          swiper3.params.spaceBetween = 10;
          // swiper3.params.allowTouchMove = false;
          // console.log(swiper3.params.allowTouchMove, 'false');
          swiper3.update();
          swiper2.params.slidesPerView = 1;
          swiper2.update();
        }
      } else {
        if (screenWidth < 500) {
          swiper3.params.slidesPerView = 3;
          swiper3.update();
          swiper2.params.slidesPerView = 1;
          swiper2.update();
        }
        if (screenWidth >= 500 && screenWidth < 768) {
          swiper3.params.slidesPerView = 3;
          swiper3.update();
          swiper2.params.slidesPerView = 2;
          swiper2.update();
        }
        if (screenWidth >= 768 && screenWidth < 1440) {
          swiper3.params.slidesPerView = 3;
          swiper3.update();
          swiper2.params.slidesPerView = 3;
          swiper2.update();
        }
        if (screenWidth >= 1440) {
          swiper3.params.slidesPerView = 'auto';
        swiper3.params.spaceBetween = 0;
          swiper3.update();
          swiper2.params.slidesPerView = 1;
          swiper2.update();
          // location.reload();
        }
      }
    });
  }

  const btnText = document.querySelector('.lots__more-button');
  if (btnText) {
    btnText.addEventListener('click', function () {
      document.querySelectorAll('.lots__photo-item--hidden').forEach(function (item) {
        item.classList.remove('lots__photo-item--hidden');
        btnText.classList.add('visually-hidden');
      });
    });
  }
});

const buttonCity = document.querySelector('[data-button="change-city"]');
buttonCity.addEventListener('click', function () {
  document.querySelector('.navigation__select-list').classList.toggle('visually-hidden');
  document.querySelector('.navigation__select-button').classList.toggle('navigation__select-button--open-nav');
});

document.querySelector('.navigation__select-list').addEventListener('click', function (evt) {
  document.querySelector('.navigation__select-list').classList.add('visually-hidden');
  document.querySelector('.navigation__select-item--active').classList.remove('navigation__select-item--active');
  buttonCity.classList.remove('navigation__select-button--open-nav');
  if (evt.target.matches('.navigation__select-item')) {
    evt.target.classList.add('navigation__select-item--active');
    buttonCity.querySelector('div').textContent = evt.target.textContent;
  }
});

const buttonNavigation = document.querySelector('[data-button="navigation"]');
buttonNavigation.addEventListener('click', function () {
  buttonNavigation.classList.toggle('navigation__button--active');
  document.querySelector('.navigation__list--desktop').classList.toggle('navigation__list--open');
  document.querySelector('.page').classList.toggle('page--dark');
});


const buttonFilter = document.querySelector('[data-button="change-filter"]');
if (buttonFilter) {
  buttonFilter.addEventListener('click', function () {
    document.querySelector('.filter-sorting__select-list').classList.toggle('visually-hidden');
    document.querySelector('.filter-sorting__select-button').classList.toggle('filter-sorting__select-button--open-nav');
  });
}

if (document.querySelector('.filter-sorting__select-list')) {
  document.querySelector('.filter-sorting__select-list').addEventListener('click', function (evt) {
    document.querySelector('.filter-sorting__select-list').classList.add('visually-hidden');
    if (document.querySelector('.filter-sorting__select-item--active')) {
      document.querySelector('.filter-sorting__select-item--active').classList.remove('filter-sorting__select-item--active');
    }
    buttonFilter.classList.remove('filter-sorting__select-button--open-nav');
    if (evt.target.matches('.filter-sorting__select-item')) {
      evt.target.classList.add('filter-sorting__select-item--active');
      document.querySelector('.filter-sorting__select').dataset.filter = evt.target.dataset.filter;
      if (document.querySelector('.filter-sorting__select').dataset.filter === "ascending-price") {
        const list = document.querySelector('.lots__list');
        var items = list.childNodes;
        var itemsArr = [];
        for (var i in items) {
          if (items[i].nodeType == 1) {
            itemsArr.push(items[i]);
          }
        }
        itemsArr.sort(function (a, b) {
          return parseFloat(a.getAttribute('data-price')) == parseFloat(b.getAttribute('data-price')) ? 0
            : (parseFloat(a.getAttribute('data-price')) > parseFloat(b.getAttribute('data-price')) ? 1 : -1);
        });
        for (i = 0; i < itemsArr.length; ++i) {
          list.appendChild(itemsArr[i]);
        }
      }
      if (document.querySelector('.filter-sorting__select').dataset.filter === "descending-price") {
        const list = document.querySelector('.lots__list');
        var items = list.childNodes;
        var itemsArr = [];
        for (var i in items) {
          if (items[i].nodeType == 1) {
            itemsArr.push(items[i]);
          }
        }
        itemsArr.sort(function (a, b) {
          return parseFloat(a.getAttribute('data-price')) == parseFloat(b.getAttribute('data-price')) ? 0
            : (parseFloat(a.getAttribute('data-price')) < parseFloat(b.getAttribute('data-price')) ? 1 : -1);
        });
        for (i = 0; i < itemsArr.length; ++i) {
          list.appendChild(itemsArr[i]);
        }
      }
      if (document.querySelector('.filter-sorting__select').dataset.filter === "start-date") {
        const list = document.querySelector('.lots__list');
        var items = list.childNodes;
        var itemsArr = [];
        for (var i in items) {
          if (items[i].nodeType == 1) {
            itemsArr.push(items[i]);
          }
        }
        itemsArr.sort(compare);

        function compare(a, b) {
          var dateA = new Date(a.getAttribute('data-timer'));
          var dateB = new Date(b.getAttribute('data-timer'));

          return dateA - dateB;
        }

        for (i = 0; i < itemsArr.length; ++i) {
          list.appendChild(itemsArr[i]);
        }
      }
      if (document.querySelector('.filter-sorting__select').dataset.filter === "end-date") {
        const list = document.querySelector('.lots__list');
        var items = list.childNodes;
        var itemsArr = [];
        for (var i in items) {
          if (items[i].nodeType == 1) {
            itemsArr.push(items[i]);
          }
        }
        itemsArr.sort(compare);

        function compare(a, b) {
          var dateA = new Date(a.getAttribute('data-timer'));
          var dateB = new Date(b.getAttribute('data-timer'));

          return dateB - dateA;
        }

        for (i = 0; i < itemsArr.length; ++i) {
          list.appendChild(itemsArr[i]);
        }
      }
    }
  });
}

initFavorite();

if (document.querySelector('.price-data__button--auto')) {
  document.querySelector('.price-data__button--auto').addEventListener('click', function () {
    document.querySelector('.price-data__modal').classList.remove('visually-hidden');
  });
  document.querySelector('.price-data__buttons-offer').querySelector('[data-button="close-modal"]').addEventListener('click', function () {
    document.querySelector('.price-data__modal').classList.add('visually-hidden');
  });
}

const priceInputModal = document.querySelector('.price-input');
const offerPriceModal = document.querySelector('.checkbox__input--offer');
if (offerPriceModal && priceInputModal) {

  offerPriceModal.addEventListener('click', function () {
    if (offerPriceModal.checked && priceInputModal.value !== "") {
      document.querySelector('.modal__button-offer').classList.add('modal__button-offer--active');
    } else {
      document.querySelector('.modal__button-offer').classList.remove('modal__button-offer--active');
    }
  });
  priceInputModal.addEventListener('input', function () {
    if (offerPriceModal.checked && priceInputModal.value !== "") {
      document.querySelector('.modal__button-offer').classList.add('modal__button-offer--active');
    } else {
      document.querySelector('.modal__button-offer').classList.remove('modal__button-offer--active');
    }
  });
}

const offerModal = document.querySelector('[data-modal="offer"]');
if (offerModal) {
  offerModal.querySelector('[data-button="close-modal"]').addEventListener('click', function () {
    offerModal.classList.add('visually-hidden');
  });
  offerModal.querySelector('[data-button="save-modal"]').addEventListener('click', function () {
    offerModal.classList.add('visually-hidden');
  });
}

document.querySelectorAll('.dropdown__button').forEach(function(item) {
  item.addEventListener('click', function (evt) {
    evt.preventDefault();
  })
})

initDropdowns();

if (document.querySelector('.price-card__reload-button')) {
  document.querySelector('.price-card__reload-button').addEventListener('click', function () {
    location.reload();
  });
}

if (document.querySelector('.price-card__reload-button--account')) {
  document.querySelector('.price-card__reload-button--account').addEventListener('click', function () {
    location.reload();
  });
}

const EMAIL_REGEXP = /^[^@\s]+@[^@\s]+\.[^@\s]+$/iu;

initPersonal();

initLogin();

initRegistration();

initRecoveryPassword();

const deleteButtons = document.querySelectorAll('.account__arhive-button--delete');
if (deleteButtons) {
  deleteButtons.forEach(i => {
    i.addEventListener('click', function (e) {
      // console.log(e.target.parentNode);
      e.target.parentNode.remove();
    });
  });
}

// document.querySelector('.authorization').classList.add('visually-hidden');
